var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{attrs:{"lg12":"","xs12":""}},[_c('v-card',[_c('v-toolbar',{staticClass:" elevation-0",attrs:{"color":"grey lighten-3"}},[_c('div',{staticClass:"text-h6 font-weight-bold py-3"},[_vm._v("Lista de Convocatorias de Postulación")])]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"mobile-breakpoint":"100","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.getEstado(item))?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.showRegistroConvocatoria(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}:null],null,true)},[_c('span',[_vm._v("Ver/Registrame")])])]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getEstado(item)?'success':'error'}},[_vm._v(" "+_vm._s(_vm.getEstado(item)?'VIGENTE':'VENCIDO')+" ")])]}},{key:"item.titulo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.titulo)+" ")]}},{key:"item.fechaInicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateText(item.fechaInicio))+" ")]}},{key:"item.fechaCaducidad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateText(item.fechaCaducidad))+" ")]}},{key:"item.rutaConvocatoria",fn:function(ref){
var item = ref.item;
return [(item.rutaConvocatoria!=null)?_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.open(item.rutaConvocatoria)}}},[_vm._v("mdi-file-pdf-outline")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }