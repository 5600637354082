<template>
    <v-flex lg12 xs12>
        <v-card>
            <v-toolbar color="grey lighten-3" class=" elevation-0">
                <div class="text-h6 font-weight-bold py-3">Lista de Convocatorias de Postulación</div>
            </v-toolbar>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    class="elevation-0"
                    mobile-breakpoint="100"
                    :loading="loading"
                >
                    <template v-slot:item.actions="{item}">
                        <v-tooltip top>
                             <template v-if="getEstado(item)" v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    v-bind="attrs"
                                    v-on="on" 
                                    @click="showRegistroConvocatoria(item)"
                                >mdi-pencil</v-icon>
                             </template>
                             <span>Ver/Registrame</span>
                        </v-tooltip>
                        
                    </template>
                    <template v-slot:item.estado="{item}">
                        <v-chip small :color="getEstado(item)?'success':'error'">
                            {{getEstado(item)?'VIGENTE':'VENCIDO'}}
                        </v-chip>
                    </template>
                    <template v-slot:item.titulo="{item}">
                        {{item.titulo}}
                    </template>
                    <template v-slot:item.fechaInicio="{item}">
                        {{formatDateText(item.fechaInicio)}}
                    </template>  
                    <template v-slot:item.fechaCaducidad="{item}">
                        {{formatDateText(item.fechaCaducidad)}}
                    </template>
                    <template v-slot:item.rutaConvocatoria="{item}">
                        <v-icon v-if="item.rutaConvocatoria!=null" color="red" @click="open(item.rutaConvocatoria)">mdi-file-pdf-outline</v-icon>
                    </template>    
                </v-data-table>
            </v-card-text>
         </v-card>  
    </v-flex>
</template>
<script>
import mixinDateTime from "@/mixins/datetime"
import mixinConvocatorias from  "@/mixins/convocatorias"
export default {
    mixins:[mixinDateTime,mixinConvocatorias],
    data(){
        return{
            loading:false,
            headers:[
                {
                    text: "ACCIONES",
                    align: "center",
                    value: "actions",
                    sortable: false,
                    width: "100px"
                },
                {
                    text: "ESTADO",
                    align: "left",
                    value: "estado",
                    sortable: false,
                    width: "100px"
                },
                {
                    text: "TITULO",
                    value: "titulo",
                    sortable: false,
                    width: "150px"
                },
                {
                    text: "FECHA INICIO",
                    value: "fechaInicio",
                    sortable: false,
                    width: "150px"
                },
                {
                    text: "FECHA FIN",
                    value: "fechaCaducidad",
                    sortable: false,
                    width: "200px"
                },
                {
                    text: "VER CONVOCATORIA",
                    value: "rutaConvocatoria",
                    sortable: false,
                    width: "200px"
                }
            ],
            items:[],
            dialog:false,
            idCurso:-1,
            idRegistroCurso:-1,
            modeloSelecionado:null
        }
    },
    created(){
      this.getDataConvocatorias()
    },
    methods:{
        getEstado(item){
            let date1 = new Date()
            let date2 = new Date(item.fechaCaducidad+'T23:59:59')
            let diference =  date2.getTime() - date1.getTime();
            return diference>0
        },
        showRegistroConvocatoria(item){
            this.$router.push('/convocatorias/'+item.id)
        },
        open(url){
            window.open(this.$docUrl+url)
        }
    }
}
</script>