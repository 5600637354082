export default {
    methods:{
        getDataConvocatorias(){
            this.loading=true
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/convocatorias-tribunal`).then(response=>{
                this.items=response.data.data
                this.loading=false
            }).catch(error=>{
                this.loading=false
                console.log(error)
            })
        }
    }
}